@import "~@/erp/styles/variables/variables.scss";





















































































































































































.detail-content {
  padding: 0 20px 90px;
  position: relative;
}
.custom-container {
  overflow-y: hidden;
}
